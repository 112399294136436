@import "../../../styles/colors.less";
@import "../../../styles/generic.less";
.reactable {
  background-color: #fff;
  margin-bottom: 0;
  .box-shadow;

}
.reactable>thead>tr>th {
  background-color: @color-beige;
  color:#1C384A;
  vertical-align: middle;
  text-transform: uppercase;
  text-align: left;
  font-size: 14px;
  font-weight: 500; 
  padding: 10px !important; 
  strong{
    font-weight: 500; 
  }
}

.reactable>tbody>tr>td,
.reactable>tbody>tr>th,
.reactable>tfoot>tr>td,
.reactable>tfoot>tr>th,
.reactable>thead>tr>th
{
    padding: 5px;
    vertical-align: middle;
    text-align: left;
    box-sizing: border-box;
    border: 1px solid #f0f0f0;
}

.reactable  tr > td,.reactable tr > th {
    vertical-align: middle;
    text-align: left;
}    


.reactable > tbody.reactable-data > tr:hover,.reactable tr > th:hover {
  background-color: @color-light-blue !important;
}

.reactable > tbody.reactable-data > tr.reactable-no-data {
  height: 100px;
  font-weight: 300;
  font-size: 16px;
  td{
      padding: 10px;
  }
}

.reactable tr:nth-child(even) {
    background: #fafafa;
}

.reactable-page-button {
  padding: 10px;
  width: 40px;
  float: left;
  cursor: pointer;
  color: #333;
  border-right: 1px solid @color-gray;
  background-color: @color-light-gray;
  text-align: center;
  margin: 10px 0px;
}
.reactable-page-button:last-child{
    margin-right:10px;
} 

.reactable-page-button:hover {
  text-decoration: none;
  color: #333;
  background-color:@color-gray;
}

.reactable-current-page {
  background-color: @color-primary;
  color:white;
  cursor: default;
}

.reactable th {
  cursor: pointer;
}

.reactable th.reactable-header-sort-desc, .reactable th.reactable-header-sort-asc {
  background-color: @color-light-info;
}

.materialicon{
    direction: ltr;
    display: inline-block;
    font-family: 'Material Icons';
    font-size: 24px;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: 1;
    text-transform: none;
    white-space: nowrap;
    word-wrap: normal;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}

.reactable th.reactable-header-sortable:after{  
    .materialicon;
    float: right;
    content: 'sort';
    font-size: 14px;
}

.reactable th.reactable-header-sort-desc:after {
    .materialicon;
    float: right;
    content: 'arrow_drop_down';
    font-size: 20px;
}

.reactable th.reactable-header-sort-asc:after {
    .materialicon;
    float: right;
    content: 'arrow_drop_up';
    font-size: 20px;
}

.reactable tr.reactable-filterer {
  width: 100%;
}

.reactable tr.reactable-filterer td {
  width: 100%;
}

.reactable-pagination{
  border-top: 2px solid @color-beige;
  td{
    padding: 0px !important;
  }
}

.reactable .reactable-filter-input {
  width: 95%;
  margin-left: 10px;
  margin-top: 7px;
  margin-bottom: 7px;
  margin-right: 20px;
  padding: 5px;
}

.reactable-previous-page,.reactable-next-page{
    color: white;
    background-color: @color-primary;
    border-radius: 50%;
    padding: 5px;
    width: 35px;
    height: 35px;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 16px;
    margin: 10px;
    float:left;
}
