@color-primary: #0292d3;
@color-primary-dark: #75d330;
@color-primary-light: #f16767;
@color-accent: #4C555C;
//@color-accent: #3A4651;
@color-accent-dark: #3A4651;
@color-mobility: #03a9f4;
@color-strength: #8bc34a;
@color-metabolic: #ffeb3b;
@color-power: #f44336;

@color-newRed: #DB303C; //new color


@color-darkgray: #666;
@color-gray: #a4b0c5;
@color-accent-light: #909DA4;
@color-light-gray: #D8DDE6;
@color-light-blue: #ECF9FF;
@color-beige: #EEEEEE;
@color-body: #F6F6F6;
@color-biscuit: #e2e2e2;


@color-blue: #03A9F4;
@color-success: #45bc00;
@color-warning: #ffc545;
@color-danger: #f16767;
@color-info: #00D0FE;
@color-light-info: #FFE76E;
@asbestos-gray: #4B555C;
@color-dark: #1C2730;

@color-facebook: #4c69ba;

.bg-success {
  background: @color-success !important;
  color: white !important;
}

.c-success {
  color: @color-success !important;
}

.bg-warning {
  background: @color-warning !important;
  color: white;
}

.c-warning {
  color: @color-warning !important;
}

.bg-danger {
  background: @color-danger !important;
  color: white !important;
}

.c-danger {
  color: @color-danger !important;
}

.bg-info {
  background: @color-info !important;
  color: white !important;
}

.c-info {
  color: @color-info !important;
}

.bg-light-info {
  background: @color-light-info !important;
}

.bg-lightgray {
  background: @color-light-gray !important;
}

.bg-gray {
  background: @color-gray !important;
}

.bg-beige {
  background: @color-beige !important;
}

.bg-body {
  background: @color-body !important;
}

.bg-biscuit {
  background: @color-biscuit !important;
}

.c-lightgray {
  color: @color-light-gray !important;
}

.c-darkgray {
  color: @color-darkgray !important;
}

.bg-blue {
  background: @color-primary !important;
  color: white !important;
}

.bg-primary {
  background: @color-primary !important;
  color: white !important;
}

.bg-primary-dark {
  background: @color-primary-dark !important;
  color: white !important;
}

.bg-accent {
  background: @color-accent !important;
  color: white !important;
}

.bg-accent-light {
  background: @color-accent-light !important;
}

.c-accent {
  color: @color-accent !important;
}

.c-accent-light {
  color: @color-accent-light !important;
}

.bg-accent-dark {
  background: @color-accent-dark !important;
  color: white !important;
}

.c-accent-dark {
  color: @color-accent-dark !important;
}

.bg-white {
  background: white !important;
}

.bg-transparent {
  background: transparent !important;
}

.c-primary {
  color: @color-primary !important;
}

.c-primary-dark {
  color: @color-primary-dark !important;
}

.c-primary-light {
  color: @color-primary-light !important;
}

.c-success {
  color: @color-success !important;
}

.c-white {
  color: white !important;
}

.c-disabled {
  color: #ccc !important;
}

.c-gray {
  color: @color-gray !important;
}

.bg-body {
  background: @color-body !important;
}

.c-flatuicolor-midnightblue {
  color: #2c3e50 !important;
}

.c-flatuicolor-pomegranate {
  color: #c0392b !important;
}

.c-flatuicolor-wisteria {
  color: #8e44ad !important;
}

.c-black {
  color: black !important;
}

.half-opacity-dark {
  background: rgba(0, 0, 0, 0.4);
}

.opacity-dark {
  background: rgba(0, 0, 0, 0.65);
}

.bg-facebook {
  background: @color-facebook !important;
  color: white !important;
}

.bg-mobility {
  background: @color-mobility !important;
  color: black !important;
}

.bg-strength {
  background: @color-strength !important;
  color: black !important;
}

.bg-metabolic {
  background: @color-metabolic !important;
  color: black !important;
}

.bg-power {
  background: @color-power !important;
  color: black !important;
}

// clients Modal 
.modal-overlay {
  position: fixed !important;
  top: 0 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 100% !important;
  width: 100% !important;
  background-color: rgba(0, 0, 0, .4) !important;
  left: 0 !important;
  z-index: 2 !important;
}

.modal-container {
  width: 600px !important;
  z-index: 3 !important;
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -30%) !important;
}

.modal-info {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.modal-close {
  position: absolute;
  background: url("../assets/cancel-icon.png");
  background-repeat: no-repeat;
  right: 20px;
  top: 13px;
  cursor: pointer;
  width: 14px;
  height: 14px;
}


// View Popup
.view-profile {
  display: flex;
  gap: 5px;
  align-items: center;
  color: white;
  cursor: pointer;
}
.profile-box{
   background: #DB303C;
   padding: 8px 7px;
   border-radius: 50%;
   border: 2px solid white;
}
.view-profile span{
  font-weight: 500;
  font-family: "Rubik", sans-serif !important;
}
.center-profile{
  justify-content: center;
  text-align: center;
}

@media screen and (max-width: 767px){
  .view-profile span{
    display: none;
  }
  .view-profile {
    gap: 10px;
  }
  .max-con{
    flex-basis: 100% !important;
    max-width: 100% !important;
    margin-top: 30px !important;
  }
  .center-profile{
    justify-content: left;
    text-align: center;
  }
  .popup-map{
    width: 92% !important;
  }
}


// View Profile
.view-con{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 60px;
}
.title{
  font-size:  24px;
  font-family: "Rubik", sans-serif !important;
  font-weight: 500;
  text-align: left;
  display: block;
}
.view-con a{
  color: #DB303C;
  font-family: "Rubik", sans-serif !important;
}
.profile-avatar-con{
  display: flex;
  gap: 10px;
  margin-bottom: 50px;
  align-items: center;
}
.icon{
    padding: 20px;
    border-radius: 50%;
    background-color: #DB303C;
    color: #fff;
    font-size: 18px;
}
.title-con{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap:2px;
}
.main-title{
  font-size: 24px;
  font-family: "Rubik", sans-serif !important;
  font-weight: 500;
}
.subtitle{
    font-size: 14px;
    color: #999999;
    font-weight: 400;
}
.info-con{
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-bottom: 40px;
}
.info-title{
  font-size: 18px;
  margin-bottom: 15px;
  text-transform: uppercase;
  font-family: avenir next, avenir, sans-serif;
  font-weight: 700;
}
.info-sub{
  padding: 16px 8px 16px 8px;
  background-color: #f7f7f7;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 15px;

}
.sub-con{
  display: flex;
  align-items: flex-start;
  gap: 8px;
}
.con-title{
  font-size: 16px;
  color: #666666;
  font-family: "Rubik", sans-serif !important;
}
.con-subtitle{
  font-size: 12px;
  color: #999999;
  font-weight: 400;
  font-family: "Rubik", sans-serif !important;
}
.social-links{
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 5px;
}

.professional-btn{
  border: 1px solid #DB303C;
  padding: 0px 12px 0px 12px;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  margin: 0 auto;
  color: #DB303C !important;
  font-family: avenir next, avenir, sans-serif !important;
}
.professional-btn:hover{
  background-color: white !important;
  color: #DB303C !important;
}

.locator-btn{
  padding: 8px 12px 8px 12px;
  border-radius: 6px;
  font-size: 12px;
  text-transform: uppercase;
  margin: 0 auto;
  color: white !important;
  background-color: #DB303C !important;
  display: flex;
  align-items: center;
  gap: 10px;
  border: 0 !important;
  font-family: avenir next, avenir, sans-serif !important;
}
.locator-btn:hover{
  background-color: #DB303C !important;
  color: white !important;
}

.discover{
  color: #333333;
  font-weight: 600;
  font-family: "Inter", sans-serif !important;
}
.discover-sub{
  font-family: "Inter", sans-serif !important;
}

.btn-width{
  width: 100%;
  margin-top: 40px !important;
}

//alert
.alert-box-con{
  display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
    background: #f7f7f7;
    padding: 12px;
    border-radius: 8px;
}
.alert-box-con ul{
  padding: 0 0 0 18px;
  margin-bottom: 0;
}
.alert-box-con ul li{
  list-style: disc !important;
  font-family: "Inter", sans-serif !important;
  color: #666666 !important;
}
.alert-header{
  font-family: "Inter", sans-serif !important;
}
.alert-info{
  font-family: "Inter", sans-serif !important;
  color: #666666 !important;
}


// Map Section
.location-container{
  margin-right: auto;
  margin-bottom: 30px;
  width: 100%;
}
.location-sec{
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 15px;
  font-size: 16px;
}
.location-sec a{
  color: #DB303C;
  font-weight: 500;
  cursor: pointer;
}
.popup-map{
  width: 75%;
  margin:  0 auto;
  background-color: white;
  box-shadow: 0;
  box-shadow: 0px 2px 4px 0px #0000001A;
  border-radius: 8px;
  z-index: 401;
  position: absolute;
  top: 50%;
  right: 50%;
  bottom: 50%;
  transform: translate(-50%,-70%);
  left: 50%;
  padding: 30px;
  height: fit-content;
}
.overlay-popup{
  background-color: rgba(0, 0, 0, 0.62);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 400;
}
.popup-sec{
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 20px;
  width: 100%;
}
.map-btn{
  padding: 12px;
  border-radius: 6px;
  font-size: 16px;
  text-transform: uppercase;
  margin: 0 auto;
  color: white !important;
  background-color: #DB303C !important;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border: 0 !important;
  text-align: center;
}
.map-btn:hover{
  background-color: #DB303C !important;
  color: white !important;
}

.card-bg{
  background: none !important;
}

.max-con{
  flex-basis: 44%;
  max-width: 44%;
  margin-top: 80px;
  margin-left: 20px;
  margin-right: 20px;
}