/*
 * Generate Margin Class
 * margin, margin-top, margin-bottom, margin-left, margin-right
 */

.margin (@label, @size: 1, @key:1) when (@size =< 30){
    .m-@{key} {
        margin: @size !important;
    }
    
    .m-t-@{key} {
        margin-top: @size !important;
    }
    
    .m-b-@{key} {
        margin-bottom: @size !important;
    }
    
    .m-l-@{key} {
        margin-left: @size !important;
    }
    
    .m-r-@{key} {
        margin-right: @size !important;
    }
    
    .margin(@label - 1; @size + 1; @key + 1);
}

.margin(25, 0px, 0);

/*
 * Generate Padding Class
 * padding, padding-top, padding-bottom, padding-left, padding-right
 */

.padding (@label, @size: 1, @key:1) when (@size =< 30){
    .p-@{key} {
        padding: @size !important;
    }
    
    .p-t-@{key} {
        padding-top: @size !important;
    }
    
    .p-b-@{key} {
        padding-bottom: @size !important;
    }
    
    .p-l-@{key} {
        padding-left: @size !important;
    }
    
    .p-r-@{key} {
        padding-right: @size !important; 
    }
    
    .padding(@label - 1; @size + 1; @key + 1);
} 

.padding(25, 0px, 0);

auto-margin{
    margin: 0 auto !important;
}

/*
 * Float
 */
.pull-left{
  float:left !important;
}
.pull-right{
  float:right !important;
}

/*
 * Font Weight
 */
.f-300 { font-weight: 300 !important; }
.f-400 { font-weight: 400 !important; }
.f-500 { font-weight: 500 !important; }
.f-700 { font-weight: 700 !important; }

/*
 * Position Classes
 */
.p-relative { position: relative !important; }
.p-absolute { position: absolute !important; }
.p-fixed { position: fixed !important; }
.p-static { position: static !important; }

/*
 * Overflow
 */
.o-hidden { overflow: hidden !important; }
.o-visible { overflow: visible !important; }
.o-auto { overflow: auto !important; }

/*
 * Display
 */
.d-block { display: block !important; }
.di-block { display: inline-block !important; } 
.d-flex { display: flex !important; } 
.hide{display: none !important; }
.v-hide{visibility: hidden !important; }

/*
 * Align
 */

.text-left{
  text-align: left !important;
}
.text-center{
  text-align: center !important;
}
.text-right{
  text-align: right !important;
}
.align-center{
  align-items: center !important;
}
.align-left{
  align-items: flex-start !important;
}
.align-right{
  align-items: flex-end !important;
}
.justify-center{
  justify-content: center !important;
}
.justify-left{
  justify-content: flex-start !important;
}
.justify-right{
  justify-content: flex-end !important;
}

 .vertical-align {
    display: flex;
    align-items: center;
}

.vertical-middle{
    vertical-align: middle;
}

.truncate-wrap {
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncate {
  .truncate-wrap;
  white-space: nowrap;
}

.pre-wrap {
    white-space: pre-line !important;
}

.no-wrap {
    white-space: nowrap !important;
}

.half-opacity{
  opacity:0.5 !important;
}

.full-height{
  min-height: 100vh;
}

.flex-auto{
    flex: auto!important;
}
.flex-1{
    flex:1 auto!important; //Don't use, issue in firefox;
}
.flex-wrap{
    flex-wrap: wrap !important;
}

.flex-column{
    flex-direction: column !important;
}
.flex-row{
    flex-direction: row !important;
}


/*
 * Border
 */
.b-0 { border: 0 !important; }

.b-1{border: 1px solid rgba(0, 0, 0, 0.2) !important;}

.br-50 {border-radius: 50%;}

.br-50em {border-radius: 50em;}

.border-body{border: 1px solid #ccc};

.border-bottom{border-bottom: 1px solid #ccc};

.border-bottom-dashed{border-bottom: 1px dashed #ccc};

/*
 * width
 */
.w-10{
  width: 10% !important;
}
.w-20{
  width: 20% !important;
}
.w-30{
  width: 30% !important;
}
.w-40{
  width: 40% !important;
}
.w-50{
  width: 50% !important;
}
.w-60{
  width: 60% !important;
}
.w-70{
  width: 70% !important;
}
.w-80{
  width: 80% !important;
}
.w-90{
  width: 90% !important;
} 
.w-100 { width: 100% !important; }

.w-auto {width: auto !important;}

/*
 * Border Radius 
 */ 
.brd-2 { border-radius: 2px; }

/*
 * Text Transform
 */
.t-uppercase { text-transform: uppercase; }

/*Font Size*/
.f-8{
  font-size: 8px !important;
}
.f-9{
  font-size: 9px !important;
}
.f-10{
  font-size: 10px !important;
}
.f-11{
  font-size: 11px !important;
}
.f-12{
  font-size: 12px !important;
}
.f-13{
  font-size: 13px !important;
}
.f-14{
  font-size: 14px !important;
}
.f-15{
  font-size: 15px !important;
}
.f-16{
  font-size: 16px !important;
}
.f-17{
  font-size: 17px !important;
}
.f-18{
  font-size: 18px !important;
}
.f-19{
  font-size: 19px !important;
}
.f-20{
  font-size: 20px !important;
}
.f-21{
  font-size: 21px !important;
}
.f-22{
  font-size: 22px !important;
}
.f-24{
  font-size: 24px !important;
}
.f-25{
  font-size: 24px !important;
}
.f-28{
  font-size: 28px !important;
}
.f-35{
  font-size: 35px !important;
}
.f-40{
  font-size: 40px !important;
}
.f-50{
  font-size: 50px !important;
}

.fa-scale {
    font-size: 1.5em !important;
}

/* Box shadow */
.no-box-shadow{
  box-shadow:none !important;
}
.no-text-shadow{
  text-shadow:none !important;
}
.text-shadow{
    text-shadow: 1px 2px 1px #666;
}

.box-shadow{
    -moz-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.4) !important;
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.4) !important;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.4) !important;
}

.box-shadow-inset{
  -moz-box-shadow:inset 0 0 2px#888 !important;
  -webkit-box-shadow:inset 0 0 2px#888 !important;
  box-shadow: inset 0 0 2px#888 !important;
}


/*Remove Input Style*/
.unstyled{
  border:none;
  box-shadow:none !important;
  background:transparent !important;
  padding:0px;
}



/* Clear row of bootstrap */
.clear-row{
  margin-left: -15px !important;
  margin-right: -15px !important;
}

.row-margin{
    margin-right: -0.5rem;
    margin-left: -0.5rem;
}

.line-through{
	text-decoration: line-through;
}

.self-center{
	align-self: center;
}

.f-italic{
	font-style: italic;
}
.cursor-pointer,.pointer{
	cursor: pointer;
}
.cursor-default{
	cursor: default !important;
}
.word-wrap{
    word-wrap: break-word;
}

.colon-right:after{
	content: ' : '
} 
.grayscale-filter{
    filter: grayscale(100%);
}

.icon-shadow-big{
    text-shadow: 0 1px 0 #ccc, 0 1px 0 #c9c9c9, 0 1px 0 #bbb, 0 1px 0 #b9b9b9, 0 2px 0 #aaa, 0 2px 1px rgba(0,0,0,.1), 0 0 2px rgba(0,0,0,.1), 0 1px 2px rgba(0,0,0,.3), 0 1px 2px rgba(0,0,0,.2), 0 2px 3px rgba(0,0,0,.25), 0 4px 4px rgba(0,0,0,.2), 0 5px 5px rgba(0,0,0,.15)
}
.icon-shadow{
    text-shadow: 1px 3px 2px rgba(0,0,0,0.20);
}
.capitalize {
    text-transform: capitalize;
}