// out: ../../dist/helper.css, compress: false, sourceMap: false
// @import "https://fonts.googleapis.com/css?family=Roboto:300,400,500";
//@import url('https://fonts.googleapis.com/css?family=Open+Sans');
//@import url('https://fonts.googleapis.com/css?family=Lato:300,400,500');
@import "./generic.less";
@import "./colors.less";
@import "./ui.less";
@import "./reactselect.less";
@font-face {
	font-family: "aileron";
	src: url("../fonts/Aileron-Light.otf");
	font-weight: normal;
	font-style: normal;
}

* {
	min-height: 0;
	min-width: 0;
}

body,
html {
	font-family: "Roboto", sans-serif;
	background: @color-body !important;
	height: 100%;
	//color:#1C384A;
	color: @color-accent-dark;
}

.gomotive-icons {
	font-family: "gomotive" !important;
	font-style: normal !important;
	font-variant: normal !important;
	text-transform: none !important;
	speak: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 2.4rem;
}

.main-container.image-bg,
.main-container.dhf-bg {
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 0% 0%;
	.holder {
		//background: url("/site_media/static/images/tile.png");
		background-color: rgba(0, 0, 0, 0.4);
	}
	.main-app-bar {
		background: none !important;
		border-bottom: 1px solid white;
	}
	.main-drawer {
		aside[data-react-toolbox="nav-drawer-content"] {
			background-color: transparent !important;
			border-right: 1px solid white !important;
			width: 32rem !important;
			ul {
				background-color: transparent !important;
				li {
					color: white;
					a {
						color: white !important;
						&:hover {
							color: @color-primary !important;
							span {
								color: @color-primary !important;
							}
						}
						&.active {
							border-radius: 0px !important;
							border: none !important;
							color: @color-primary-dark !important;
							span {
								color: @color-primary-dark !important;
							}
						}
						span {
							color: white !important;
						}
					}
				}
			}
		}
	}
}

body * {
	font-family: "Roboto", sans-serif;
}
h1,
h2,
h3,
h4,
h5 {
	font-family: "aileron", "Roboto", sans-serif;
	font-weight: 300;
	width: 100%;
}

h5,
h6 {
	font-weight: normal;
}

h4.flex-auto,
h5.flex-auto,
h6.flex-auto {
	width: auto;
}

h1,
h2,
h3,
h4,
h5,
h6,
label,
p,
button,
abbr,
a,
span,
small {
	font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
}

hr {
	width: 100%;
	clear: both;
	&.dashed {
		border-top: 1px dashed #e2e2e2;
		border-bottom: 1px dashed #fff;
		&.dark {
			border-top: 1px dashed #666;
			border-bottom: 1px dashed #ccc;
		}
	}
}

table {
	width: 100%;
	color: #1c384a;
}

form {
	color: #1c384a;
}

div[data-react-toolbox="dialog"] section[role="body"] {
	color: #1c384a;
}
span[data-react-toolbox="tooltip"] {
	z-index: 500;
	white-space: pre-line !important;
}

label[data-react-toolbox="checkbox"] {
	height: auto;
	> span[data-react-toolbox="label"] {
		white-space: pre-line;
		word-wrap: break-word;
	}
}

.main-app-bar {
	height: 4.4rem;
	.no-box-shadow;
	h1[class^="theme__title"] {
		font-size: 2.6rem;
		font-weight: 300;
		height: 35px;
		overflow: hidden;
	}
	a.active {
		background: rgba(0, 0, 0, 0.2);
		border-radius: 50em;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
.main-app-bar-gi {
	background: white;
	color: @color-accent-dark;
	border-bottom: 1px solid #bbb;
}
.left-side-menu {
	width: 60px;
	padding: 0px;
	min-height: calc(100vh - 6rem);
	flex-direction: column;
	background: white;
	color: #1c384a;
	font-size: 1.5em;
	border-right: 1px solid #bbb;
	text-align: center;
	overflow: auto;
	box-shadow: rgb(136, 136, 136) 0px 0px 2px inset;
	a {
		display: block;
		opacity: 0.5;
		line-height: 2.2;
		margin: 0px !important;
		&:hover {
			opacity: 1;
			background: #fafafa;
			//border-bottom: 2px solid @color-primary;
		}
		span.material-icons {
			font-size: 3.5rem;
			color: #1c384a;
			margin: 0px !important;
			vertical-align: middle;
		}
		span.gomotive-icons {
			font-size: 3.5rem;
			color: #1c384a;
			margin: 0px !important;
			vertical-align: middle;
		}
	}
	a.active {
		opacity: 1;
		background: #fafafa;
		border-left: 4px solid @color-primary !important;
		box-shadow: rgb(136, 136, 136) 0px 0px 1px inset;
	}
}
.sub-menu {
	width: 100%;
	line-height: 42px;
	padding: 0px 5px;
	// color: white;
	// background: #4C555C;
	// -webkit-box-shadow: -1px 0px 0px rgba(255,255,255,0.6);
	// -moz-box-shadow: -1px 0px 0px rgba(255,255,255,0.6);
	// box-shadow: -1px 0px 0px rgba(255,255,255,0.6);
	// background: -webkit-gradient(radial, center center, 0, center center, 171, from(#5e6972), to(#4C555C));
	// background: -webkit-radial-gradient(circle, #5e6972, #4C555C);
	// background: -moz-radial-gradient(circle, #5e6972, #4C555C);
	// background: -ms-radial-gradient(circle, #5e6972, #4C555C);
	// background: radial-gradient(circle, #5e6972, #4C555C);
	vertical-align: middle;
	text-align: center;
	a {
		color: white !important;
		opacity: 0.85;
		transition: opacity 0.35s cubic-bezier(0.4, 0, 0.2, 1);
		display: inline-block;
		vertical-align: middle;
		margin: 0 0.5rem;
		&.active {
			opacity: 1;
			abbr {
				background: @color-primary-dark;
				font-weight: 500;
				.box-shadow-inset;
			}
		}
		&:hover {
			opacity: 1;
			abbr {
				background: @color-primary;
			}
		}
		.material-icons {
			font-size: 1.8rem;
			margin-right: 0.5rem;
			vertical-align: middle;
		}
		.gomotive-icons {
			font-size: 1.8rem;
			margin-right: 0.5rem;
			vertical-align: middle;
		}
		abbr {
			vertical-align: middle;
			padding: 5px 10px;
			background-color: @color-accent-dark;
			border-radius: 50em;
		}
	}
}

//Redux Form Field overide
.input-text-dark {
	.Select-control {
		border-bottom: 1px solid #666666;
	}
	textarea {
		border-bottom: 1px solid #666666;
	}
	input {
		color: #666666;
		border-bottom: 1px solid #666666;
	}
	label {
		color: #666666;
	}
}

//Goal Template Action Text
.goal-template-action-container {
	display: flex;
}
.goal-template-action {
	cursor: pointer;
	box-shadow: none;
	&[disabled] {
		opacity: 0.5;
		filter: grayscale(100);
	}
	&:hover {
		box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
			0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
	}
	&.secondary-button {
		color: @color-primary !important;
		// border: 1px solid @color-primary;
		background-color: transparent !important;
	}
	&.delete-button {
		color: @color-power !important;
		// border: 1px solid @color-power;
		background-color: transparent !important;
		box-shadow: none;
		&:hover {
			box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
				0 3px 1px -2px rgba(0, 0, 0, 0.2),
				0 1px 5px 0 rgba(0, 0, 0, 0.12);
		}
	}
	&:hover {
		color: @color-primary;
	}
	&.red {
		&:hover {
			color: @color-power;
		}
	}
}

button[disabled] {
	opacity: 0.5;
}

.form-center {
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: center;
}

.full-panel {
	min-height: 100vh !important;
	justify-content: flex-start !important;
}

.nsProgress_block {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1999;
}

.nsProgress_container {
	background: rgba(0, 0, 0, 0.8);
	width: 120px;
	height: 90px;
	position: fixed;
	top: 35%;
	left: 50%;
	margin-left: -60px;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
	z-index: 2000;
	display: flex;
	align-items: center;
	justify-content: center;
}

.main-drawer {
	// width: 0 !important;
 	&[class*="active"] {
		width: 20rem !important;
	}
	aside[data-react-toolbox="nav-drawer-content"] {
		background-color: @color-body !important;
		border: none !important;
		box-shadow: none !important;
		width: 20rem !important;
		color: @color-dark !important;
		ul {
			background-color: @color-body !important;
			h5 {
				color: @color-dark !important;
			}
			li {
				position: relative;
				// border-top: 1px solid #4C555C;
				// border-bottom: 1px solid #272c2f;
				transition: all 0.5s ease;
				-webkit-transition: all 0.5s ease;
				-moz-transition: all 0.5s ease;
				-ms-transition: all 0.5s ease;
				-o-transition: all 0.5s ease;
				color: @color-dark !important;
				a {
					padding: 3px 10px;
					line-height: 30px;
					margin-left: 10px;
					margin-bottom: 5px;
					width: auto;
					display: block;
					font-size: 15px;
					&:hover {
						color: @color-primary !important;
						span {
							color: @color-primary !important;
						}
					}
					&.active {
						// background: -moz-linear-gradient(45deg, rgba(69,188,0,1) 2%, rgba(69,188,0,1) 9%, rgba(117,211,48,1) 51%, rgba(69,188,0,1) 91%, rgba(69,188,0,1) 100%);
						// background: -webkit-gradient(linear, left bottom, right top, color-stop(2%, rgba(69,188,0,1)), color-stop(9%, rgba(69,188,0,1)), color-stop(51%, rgba(117,211,48,1)), color-stop(91%, rgba(69,188,0,1)), color-stop(100%, rgba(69,188,0,1)));
						// background: -webkit-linear-gradient(45deg, rgba(69,188,0,1) 2%, rgba(69,188,0,1) 9%, rgba(117,211,48,1) 51%, rgba(69,188,0,1) 91%, rgba(69,188,0,1) 100%);
						// background: -o-linear-gradient(45deg, rgba(69,188,0,1) 2%, rgba(69,188,0,1) 9%, rgba(117,211,48,1) 51%, rgba(69,188,0,1) 91%, rgba(69,188,0,1) 100%);
						// background: -ms-linear-gradient(45deg, rgba(69,188,0,1) 2%, rgba(69,188,0,1) 9%, rgba(117,211,48,1) 51%, rgba(69,188,0,1) 91%, rgba(69,188,0,1) 100%);
						// background: linear-gradient(45deg, rgba(69,188,0,1) 2%, rgba(69,188,0,1) 9%, rgba(117,211,48,1) 51%, rgba(69,188,0,1) 91%, rgba(69,188,0,1) 100%);
						// filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#45bc00', endColorstr='#45bc00',GradientType=1 );
						// -moz-box-shadow: inset 0 0 5px #45bc00;
						// -webkit-box-shadow: inset 0 0 5px#45bc00;
						// box-shadow: inset 0 0 8px #45bc00;

						// background: -moz-linear-gradient(45deg, rgba(2,139,201,1) 2%, rgba(2,139,201,1) 9%, rgba(3,169,244,1) 51%, rgba(2,139,201,1) 91%, rgba(2,139,201,1) 100%); /* ff3.6+ */
						// background: -webkit-gradient(linear, left bottom, right top, color-stop(2%, rgba(2,139,201,1)), color-stop(9%, rgba(2,139,201,1)), color-stop(51%, rgba(3,169,244,1)), color-stop(91%, rgba(2,139,201,1)), color-stop(100%, rgba(2,139,201,1))); /* safari4+,chrome */
						// background: -webkit-linear-gradient(45deg, rgba(2,139,201,1) 2%, rgba(2,139,201,1) 9%, rgba(3,169,244,1) 51%, rgba(2,139,201,1) 91%, rgba(2,139,201,1) 100%); /* safari5.1+,chrome10+ */
						// background: -o-linear-gradient(45deg, rgba(2,139,201,1) 2%, rgba(2,139,201,1) 9%, rgba(3,169,244,1) 51%, rgba(2,139,201,1) 91%, rgba(2,139,201,1) 100%); /* opera 11.10+ */
						// background: -ms-linear-gradient(45deg, rgba(2,139,201,1) 2%, rgba(2,139,201,1) 9%, rgba(3,169,244,1) 51%, rgba(2,139,201,1) 91%, rgba(2,139,201,1) 100%); /* ie10+ */
						// background: linear-gradient(45deg, rgba(2,139,201,1) 2%, rgba(2,139,201,1) 9%, rgba(3,169,244,1) 51%, rgba(2,139,201,1) 91%, rgba(2,139,201,1) 100%); /* w3c */
						// filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#028bc9', endColorstr='#028bc9',GradientType=1 ); /* ie6-9 */
						// -moz-box-shadow: inset 0 0 5px #0276ab;
						// -webkit-box-shadow: inset 0 0 5px#0276ab;
						// box-shadow: inset 0 0 8px #0276ab;
						//background: @color-primary-dark;
						border-radius: 50em;
						border: 1px solid @color-primary-dark;
						color: @color-primary-dark !important;
						span {
							color: @color-primary-dark !important;
						}
					}
				}
				* {
					color: @color-dark !important;
				}
				span.material-icons {
					margin-right: 1.5rem;
					vertical-align: middle;
				}
				span.gomotive-icons {
					margin-right: 1.5rem;
					vertical-align: middle;
				}
			}
		}
	}
}

.main-drawer.narrow {
	&[class*="active"] {
		width: 14rem !important;
	}
	aside[data-react-toolbox="nav-drawer-content"] {
		width: 14rem !important;
		ul {
			li {
				a {
					min-height: 60px;
				}
			}
		}
	}
}

.main-drawer.accent {
	aside[data-react-toolbox="nav-drawer-content"] {
		background-color: @color-accent-dark !important;
		color: white !important;
		ul[data-react-toolbox="list"] {
			li {
				color: white !important;
				* {
					color: white !important;
				}
				> span:first-child:hover {
					background-color: #222 !important;
				}
			}
			hr {
				background: #bbb !important;
			}
		}
	}
}

div[data-react-toolbox="card"] {
	div[class^="theme__cardTitle"] {
		padding: 1rem 1.6rem 1.4rem !important;
		background: #4c555c;
		color: white;
	}
	h5[class^="theme__title"] {
		font-size: 2rem !important;
	}
	p[class^="theme__subtitle__"] {
		color: #eee !important;
	}
}

div[data-react-toolbox="chip"][class^="theme__avatar__"] {
	padding-left: 0;
}

div[data-react-toolbox="chip"] > div[data-react-toolbox="avatar"] {
	width: 3.2rem;
	height: 3.2rem;
	margin-right: 0.8rem;
	vertical-align: middle;
}

.card-full-height {
	min-height: 45vh;
}

.add-float-button {
	position: fixed;
	right: 10 !important;
	bottom: 10 !important;
}

.material-icons.icon-scale {
	font-size: 28px !important;
}
.gomotive-icons.icon-scale {
	font-size: 28px !important;
}

.material-label {
	color: rgba(0, 0, 0, 0.26);
	font-size: 1.2rem;
	line-height: 1.6rem;
	display: flex;
	margin-top: 10px;
	margin-top: 5px;
}

.alert-warning {
	background-color: @color-light-info !important;
	padding: 10px;
	font-size: 15px;
}
.full-dialog {
	width: 96vw !important;
}

.full-height-dialog {
	> section > div {
		min-height: 90vh;
	}
}

#chart-container {
	position: relative;
	display: inline-block;
	top: 10px;
	left: 10px;
	min-height: 420px;
	height: calc(100% - 24px);
	width: 100%;
	border: 2px dashed #aaa;
	border-radius: 5px;
	overflow: auto;
	text-align: center;
	.orgchart {
		background-image: none;
		.node {
			.rightEdge {
				display: none !important;
				pointer-events: none !important;
			}
			.leftEdge {
				display: none !important;
				pointer-events: none !important;
			}
			.topEdge {
				display: none !important;
				pointer-events: none !important;
			}
			.title {
				background-color: @color-primary-dark;
			}
			.content {
				border-color: @color-primary;
				height: auto;
				white-space: pre-wrap;
			}
		}
		.verticalNodes {
			td::before {
				border-color: @color-primary !important;
			}
			ul > li::before,
			ul > li::after {
				border-color: @color-primary !important;
			}
		}
		tr.lines td {
			border-color: @color-primary !important;
			.downLine {
				background-color: @color-primary;
			}
		}
	}
}

//Tinymce style overide

.mce-tinymce {
	.mce-panel {
		background-color: @color-light-gray;
	}
	.mce-btn.mce-active,
	.mce-btn.mce-active:hover {
		background-color: @color-primary;
		border-color: @color-primary-dark;
	}
	.mce-btn {
		background-color: @color-light-gray;
		&:hover {
			background-color: @color-primary;
			border-color: @color-primary-dark;
		}
		&:focus {
			background-color: @color-primary;
			border-color: @color-primary-dark;
		}
	}

	.mce-btn-group:not(:first-child) {
		border-left: 1px solid #9d9d9d;
	}
}

.left-main-menu {
	overflow: auto;
	&.accent {
		background: @color-accent-dark;
		color: white;
	}
}

.custom-divider .panelGroup {
	.panelWrapper {
		padding: 5px;
		overflow: auto !important;
	}
	.divider {
		width: 5px !important;
		max-width: 5px !important;
		// background-image: url(/site_media/static/images/vertical.png) !important;
		background-repeat: no-repeat !important;
		background-position: 50% !important;
		background-color: #eee !important;
		.box-shadow;
	}
}

.floating-add-button {
	position: fixed;
	bottom: 20px;
	right: 20px;
	top: auto !important;
	background: @color-success !important;
	color: white !important;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 40px !important;
	width: 40px !important;
	&.floating-add-button-bottom {
		position: fixed;
		bottom: 20px;
		right: 20px;
		top: auto;
	}
}

.floating-cancel-button {
	position: absolute;
	right: 20px;
	top: 60px;
	background: @color-danger !important;
	color: white !important;
}

.tether-element {
	z-index: 99999;
}

.admin-background {
	background: @color-danger !important;
	color: white !important;
	strong {
		color: white !important;
	}
}

div[data-react-toolbox="tabs"] > section {
	overflow: auto !important;
}

.custom-tab {
	span[class^="theme__pointer"] {
		height: 0.4rem;
		margin-top: -0.4rem;
	}
	&.beige-section {
		section {
			background: @color-body;
		}
	}
}

.white-section {
	section {
		background: white;
	}
}

.custom-tab.accent > div[class^="theme__navigationContainer__"] {
	nav[class^="theme__navigation"] {
		background: @color-accent !important;
		label[class*="theme__disabled"] {
			opacity: 0.5 !important;
		}
	}
	span[class^="theme__pointer"] {
		height: 0.5rem;
		margin-top: -0.5rem;
		background-color: @color-success !important;
	}
}

.custom-tab.no-padding {
	section {
		padding: 0px !important;
	}
}

label[class^="theme__disabled"] {
	span[class^="theme__on"] {
		span[class^="theme__thumb"] {
			background-color: @color-primary !important;
		}
	}
	span[class^="theme__off"] {
		span[class^="theme__thumb"] {
			background-color: #fafafa !important;
		}
	}
}

div[class^="theme__input___"] {
	label[class^="theme__label___"] {
		top: 0.6rem !important;
		font-size: 1.2rem !important;
	}
}
div[class*="theme__disabled"] {
	input {
		color: #212121 !important;
	}
	textarea {
		color: #212121 !important;
	}
}

div[data-react-toolbox="date-picker"]
	input[class^="theme__inputElement"][readonly] {
	color: #212121;
}
div[data-react-toolbox="time-picker"]
	input[class^="theme__inputElement"][readonly] {
	color: #212121;
}

section[class^="theme__body"] {
	overflow: auto !important;
}

.switch-school-year button {
	display: none !important;
}
.switch-institution-year button {
	display: none !important;
}
.small-button {
	font-size: 1.1rem !important;
	line-height: 2rem !important;
	height: 3rem !important;
	white-space: normal !important;
	padding: 0.5rem !important;
	min-width: 40px;
	span.material-icons {
		line-height: 2rem;
	}
	span.gomotive-icons {
		line-height: 2rem;
	}
}
.big-button {
	font-size: 2.8rem !important;
	line-height: 5rem !important;
	height: 5rem !important;
	white-space: normal !important;
	padding: 5px 20px;
	min-width: 80px;
}
.small-icon-button {
	font-size: 1.2rem;
	line-height: 2.8rem !important;
	height: 2.8rem !important;
	white-space: normal !important;
	padding: 4px !important;
	width: 2.8rem !important;
	span.material-icons {
		line-height: 1.6rem;
		.f-16;
	}
	span.gomotive-icons {
		line-height: 1.6rem;
		.f-16;
	}
	&.round-border {
		border: 1px solid;
		border-radius: 50%;
	}
}

.button-border {
	border: 2px solid #1c384a;
}
.round-border {
	.button-border;
	border-radius: 50%;
}

.big-label {
	font-size: 1.8rem;
	color: #1c384a;
	font-family: "Roboto", sans-serif;
}
.medium-label {
	font-size: 1.6rem;
	color: #1c384a;
	font-family: "Roboto", sans-serif;
}
.small-label {
	font-size: 1.4rem;
	color: #1c384a;
	font-family: "Roboto", sans-serif;
}

form.big-labels {
	div[class^="theme__input___"] label[class^="theme__label___"] {
		top: 0.2rem !important;
		.big-label;
		font-size: 1.8rem !important;
	}
	div[class^="theme__input___"] {
		span[class^="theme__hint___"] {
			padding-left: 5px;
			font-size: 1.6rem !important;
		}
	}
	label {
		.big-label;
	}
}
form.medium-labels {
	div[class^="theme__input___"] label[class^="theme__label___"] {
		top: 0.2rem !important;
		.medium-label;
		font-size: 1.6rem !important;
	}
	div[class^="theme__disabled"] label[class^="theme__label___"] {
		opacity: 0.5 !important;
	}
	div[class^="theme__input___"] {
		span[class^="theme__hint___"] {
			padding-left: 5px;
			font-size: 1.4rem !important;
		}
	}
	label {
		.medium-label;
	}
}

form.white-labels {
	div[class^="theme__input___"] label[class^="theme__label___"] {
		color: white;
	}
	label {
		color: white;
	}
	span {
		color: white;
	}
	input {
		border-bottom: 1px solid white !important;
		color: white !important;
	}
}

form.small-labels {
	div[class^="theme__input___"] label[class^="theme__label___"] {
		top: 0.2rem !important;
		.small-label;
		font-size: 1.4rem !important;
	}
	div[class^="theme__input___"] {
		span[class^="theme__hint___"] {
			font-size: 1.2rem !important;
			padding-left: 5px;
		}
	}
	label {
		.small-label;
	}
}

.icon-menu-medium-white {
	> button > span {
		.f-35;
		.c-white;
	}
}

.thumbnail {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 0% 0%;
}

.width-350 {
	width: 300px !important;
}
.width-300 {
	width: 300px !important;
}

.width-250 {
	width: 250px !important;
}

.width-150 {
	width: 150px !important;
}
.width-100 {
	width: 100px !important;
}

.box-300-225 {
	.width-300;
	height: 225px !important;
}

.box-250-150 {
	.width-250;
	height: 150px !important;
}

.box-150-150 {
	width: 150px !important;
	height: 150px !important;
}
.box-250-250 {
	width: 250px !important;
	height: 250px !important;
}

.box-150-90 {
	.width-150;
	height: 90px !important;
}


.profile-picture-normal {
	width: 150px;
	height: 150px;
	justify-content: center;
	align-items: center;
	background-color: #969696;
}

.profile-picture-normal-no-avatar {
	border: 1px solid white !important;
	padding: 10px;
	border-radius: 50%;
	.f-50;
	.c-white;
}

.profile-picture-big {
	width: 250px;
	height: 250px;
	justify-content: center;
	align-items: center;
}

.profile-picture-big-no-avatar {
	border: 1px solid white !important;
	padding: 10px;
	border-radius: 50%;
	.f-50;
	.c-white;
}

.box-100 {
	height: 100px;
	width: 100px;
}

.avatar-image-100 {
	> img {
		.box-100;
	}
}
.progress-bar-height {
	height: 30px !important;
}
.preview-dialog {
	width: 100vw !important;
	height: 100vh !important;
	> section > div {
		min-height: 100vh;
	}
	> section {
		padding: 0px;
	}
}
.dialog-title-center {
	> section > h6 {
		justify-content: center;
		text-align: center;
		-webkit-box-pack: center;
	}
}
.dialog-wrap-content {
	width: auto;
	height: auto;
}
.big-date-picker {
	// > DayPicker > DayPicker-wrapper > DayPicker-Month
	> div[class="DayPicker-wrapper"] > div[class="DayPicker-Month"] {
		width: 400px;
		height: 300px;
		> div[class="DayPicker-Caption"] {
			padding-bottom: 40px;
		}
	}
}

.ui-sortable .ui-sortable-placeholder.visible {
	z-index: -1;
	opacity: 0.5;
	-ms-filter: opacity(alpha=50);
	border: 2px @color-accent-dark dashed;
}

.hide-hover-icon {
	background: transparent !important;
	font-size: 24px !important;
}

.profile-avatar {
	position: relative;
	display: inline-block;
	width: 4rem;
	height: 4rem;
	overflow: hidden;
	font-size: 2rem;
	color: white;
	text-align: center;
	vertical-align: middle;
	border-radius: 50%;
	background-color: #969696;
}

.chip-thin {
	line-height: 2rem;
}

.slick-prev:before,
.slick-next:before {
	color: @color-success !important;
	font-size: 24px;
}

.slick-prev {
	left: -20px;
}
.slick-next {
	right: -15px;
}

div.round-number {
	border-radius: 50%;
	width: 35px;
	font-size: 18px;
	border: 2px solid;
	color: @color-dark;
	> span {
		text-align: center;
		line-height: 35px;
		display: block;
	}
	&.small {
		width: 25px;
		font-size: 16px;
		> span {
			line-height: 25px;
		}
	}
}
.main-container .ui-sortable .ui-sortable-item {
	cursor: default;
}
.ribbon {
	transform: rotate(-45deg);
	position: absolute;
	margin-top: 14px;
	width: 100px;
	margin-left: -23px;
	text-align: center;
	padding: 3px;
}
.ribbon-container {
	position: relative;
	overflow: visible;
}

.line-chart-wrapper {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;
	font-size: 16px !important;
}

.activity-icon {
	float: none !important;
	margin-left: auto !important;
	margin-right: auto !important;
	border: 1px solid rgba(0, 0, 0, 0.2) !important;
	-moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
	-webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
	display: inline-block;
	background: #999;
	width: 59px;
	height: 59px;
	font-size: 30px;
	color: #fff;
	text-align: center;
	border-radius: 50em;
	-moz-border-radius: 50em;
	-webkit-border-radius: 50em;
}

button[class*="theme__floating"] {
	&.nano {
		width: 2rem !important;
		height: 2rem !important;
		span.material-icons {
			font-size: 14px !important;
			line-height: 2rem !important;
		}
		span.gomotive-icons {
			font-size: 16px !important;
			line-height: 2rem !important;
		}
	}
	&.micro {
		width: 3rem !important;
		height: 3rem !important;
		span.material-icons {
			font-size: 18px !important;
			line-height: 3rem !important;
		}
		span.gomotive-icons {
			font-size: 18px !important;
			line-height: 3rem !important;
		}
	}
	&.large {
		width: 8rem !important;
		height: 8rem !important;
		span.material-icons {
			font-size: 30px !important;
			line-height: 8rem !important;
		}
		span.gomotive-icons {
			font-size: 30px !important;
			line-height: 8rem !important;
		}
	}
}

.rotate-button-icon {
	span.material-icons {
		transform: rotate(45deg) !important;
	}
	span.gomotive-icons {
		transform: rotate(45deg) !important;
	}
}
.profile_icon_menu button {
	display: none !important;
}

.rbc-calendar {
	.rbc-toolbar .rbc-toolbar-label {
		flex: 1;
	}
	.rbc-day-slot .rbc-event {
		flex-flow: column !important;
	}
	.calendar-individual-appointment {
		background-color: #1abc9c;
	}
	.calendar-holiday {
		background-color: #c0392b;
	}
	.calendar-group-class {
		background-color: #f1c40f;
	}
	.calendar-facility-booking {
		background-color: #3498db;
	}
}

.search-box {
	box-shadow: 0 0 0 0.5px rgba(49, 49, 93, 0.03),
		0 2px 5px 0 rgba(49, 49, 93, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.08);
	background-color: #fff;
	background-position: 10px 8px;
	border-radius: 4px;
	font-weight: 400;
	font-size: 14px;
	color: #525f7f;
	border: none;
	padding: 0 7px 0 29px;
	z-index: 1;
	position: relative;
	transition: box-shadow 0.2s ease-in-out;
}

.search-box span.material-icons {
	top: 0px !important;
}
.search-box span.gomotive-icons {
	top: 0px !important;
}

ul[class^="theme__list"] {
	&.c-white * {
		color: rgba(255, 255, 255, 0.9) !important;
	}
	&.c-white {
		li {
			span[class^="theme__item__"]:nth-of-type(1) {
				white-space: pre-wrap;
				&:hover {
					background-color: rgba(0, 0, 0, 0.4) !important;
				}
			}
		}
	}
}

::-webkit-input-placeholder {
	font-size: 11px;
}
:-moz-placeholder {
	font-size: 11px;
}
::-moz-placeholder {
	font-size: 11px;
}
:-ms-input-placeholder {
	font-size: 11px;
}

.toastify {
	z-index: 999;
}

.toastify--top-right {
	top: 4em;
}

.DayPicker-Day.DayPicker-Day--selected.DayPicker-Day--disabled {
	color: #fff !important;
	background-color: #4a90e2 !important;
	opacity: 0.5;
}

.round-modal {
	height: 300px !important;
	max-height: 300px !important;
	width: 300px !important;
	border-radius: 50%;
	border: 2px solid #888;
	-moz-box-shadow: 0 0 5px #888;
	-webkit-box-shadow: 0 0 5px#888;
	box-shadow: 0 0 5px #888;
	overflow: hidden !important;
}

.swal2-popup {
	font-size: 1.5rem;
	text-align: center;
	.swal2-actions {
		display: block !important;
	}
	.swal2-styled {
		margin: 10px 5px 0;
	}
}

h6.two-lines {
	display: block;
	display: -webkit-box;
	font-size: 15px;
	line-height: 1.2;
	-webkit-line-clamp: 2;
	-moz-line-clamp: 2;
	-line-clamp: 2;
	max-height: 36px; //$font-size*$line-height*$lines-to-show Fallback for non-webkit ;
	-webkit-box-orient: vertical;
	-moz-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	word-break: break-word;
}

.u-userLauncherColor {
	padding: 5px 3px 5px 10px !important;
}

.vimeo-holder iframe {
	width: 100% !important;
	height: 100% !important;
}

form span[class^="theme__error"] {
	color: #de3226 !important;
}

.rbc-label-start {
	font-family: fontawesome;
	font-weight: bold;
	font-size: 22px;
}

label[data-react-toolbox="checkbox"].c-white > div[data-react-toolbox="check"] {
	border-color: #e2e2e2 !important;
}

div[data-react-toolbox="overlay"][class*="theme__active__"] {
	z-index: 401 !important;
}

.img-responsive {
	display: block;
	max-width: 100%;
	height: auto;
}

.image-container {
	position: relative;
	text-align: center;
	color: white;
}

.image-text-center {
	position: absolute;
	top: 90%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.menu-left-icon {
	color: black !important;
}

.notification-badge {
	position: relative;
	right: 5px;
	top: -20px;
	color: #941e1e;
	background-color: #f5f1f2;
	margin: 0 -0.8em;
	border-radius: 50%;
	padding: 5px 10px;
}

.client-homepage-images-middle > * {
	vertical-align: middle;
}
